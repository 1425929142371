<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import MainHeader from "../AppHeader";
    import MainFooter from "../AppFooter";

    const menuItems = [
        {text: 'Продукты', routeName: 'report-product'},
        {text: 'Долги', routeName: 'report-debt'},
        {text: 'Касса', routeName: 'report-payment'},
    ];

    export default {
        name: "Main",

        components: {
            MainHeader,
            MainFooter
        },

        data() {
            return {
                menuItems
            }
        },

        computed: {
            defaultActive: function () {
                return this.$router.currentRoute.name;
            }
        },

        beforeRouteEnter(to, from, next) {
            // autoredirect to first tab
            if (to.name.indexOf("report-") < 0) {
                // use next with callback to prevent "uncaught error undefined" by calling next twice
                next(vm => {
                    vm.$router.push({
                        // push to first
                        name: menuItems[0].routeName,
                        params: to.params,
                        replace: true
                    });
                });
            } else {
                next();
            }
        }
    }
</script>


<template>
    <el-container id="viewport" direction="vertical">
        <main-header></main-header>
        <el-container>
            <el-aside width="200px">
                <h1 class="aside-header">Отчетность</h1>
                <div class="humanize-container">
                    <el-menu :router="true"
                             :default-active="defaultActive">
                        <el-menu-item v-for="menu in menuItems"
                                      :key="menu.routeName"
                                      :index="menu.routeName"
                                      :route="{name:menu.routeName}">
                            {{menu.text}}
                        </el-menu-item>
                    </el-menu>
                </div>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>

        <main-footer></main-footer>
    </el-container>
</template>


<style scoped lang="scss">
    @import "../../scss/element";

    .el-aside {
        border-right: 1px solid #eeeeee;
        padding-top: 20px;
    }

    .el-menu {
        border-right: none;

        &-item {
            &.is-active {
                font-weight: 700;
                background-color: $--dropdown-menuItem-hover-fill;
            }
        }
    }

    .el-main {
        // right gap to prevent overlaping by arrow "to top"
        padding-right: 100px;
    }
</style>